import { MOBILE_TYPES } from "@/util/mobile.type";

export const sendHeadersToMobile = {
  methods: {
    sendHeadersToMobile(barcode, readyToPick) {
      if (this.isMobile) {
        if (this.platform == MOBILE_TYPES.ANDROID) {
          window.PacketaPPA.sendHeaders(barcode, readyToPick);
        } else if (this.platform == MOBILE_TYPES.IOS) {
          window.webkit.messageHandlers.sendHeaders.postMessage({
            barcode: barcode,
            readyToPick: readyToPick
          });
        }
      }
    }
  }
};
