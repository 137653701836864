<template>
  <div>
    <AppHeader v-if="!isMobile">
      <template v-slot:left-action>
        <arrow-left-icon
          class="arrow-left"
          @click.prevent="closePacketDetail"
        />
      </template>

      <template v-slot:title>
        <h2>{{ $t("packetCheck.packet-search") }}</h2>
        <h1 v-if="packet.barcode">
          Z{{ packet.barcode.substring(0, packet.barcode.length - 3)
          }}<span class="black">{{
            packet.barcode.substring(packet.barcode.length - 3)
          }}</span>
        </h1>
      </template>
    </AppHeader>

    <PacketCheckDetail
      :packet="packet"
      :sender="sender"
      v-if="!scannedBarcode || scannedBarcode === packet.barcode"
      @open-scan="openMobileScan"
    />
    <PacketCheckError
      :barcodeMatches="scannedBarcode === packet.barcode"
      v-else-if="
        (errors && errors.length > 0) || scannedBarcode !== packet.barcode
      "
      @open-scan="openMobileScan"
      @close-packet-detail="closePacketDetail"
    />

    <section class="error">
      <Errors :errors="errors" />
    </section>

    <OverlayAccept
      v-if="showAcceptOverlay"
      :heading="$t('packetCheck.release-correct-packet')"
    />
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import _ from "lodash";
import OverlayAccept from "@/components/OverlayAccept";
import ArrowLeftIcon from "vue-material-design-icons/ArrowLeft.vue";
import PacketCheckDetail from "@/components/PacketCheckDetail";
import PacketCheckError from "@/components/PacketCheckError";
import Errors from "@/components/Errors";
import AppHeader from "@/components/AppHeader";
import { getParams } from "@/mixins/platform-params.js";
import { sendHeadersToMobile } from "@/mixins/send-headers.js";
import { sendBarcodeResultToMobile } from "@/mixins/send-barcode-result.js";
import { MOBILE_TYPES } from "@/util/mobile.type";

export default {
  name: "PacketCheck",
  mixins: [getParams, sendHeadersToMobile, sendBarcodeResultToMobile],
  components: {
    PacketCheckDetail,
    PacketCheckError,
    AppHeader,
    Errors,
    ArrowLeftIcon,
    OverlayAccept
  },
  data() {
    return {
      code: this.$route.params.code,
      showAcceptOverlay: false
    };
  },

  beforeRouteLeave(to, from, next) {
    this.clearErrors();
    if (to.name == "packet-search") {
      this.resetPacketAndPaymentState();
    }
    next();
  },

  async mounted() {
    if (_.isEmpty(this.packet)) {
      await this.getPacketDetail(this.code);
    }
  },

  computed: {
    ...mapState(["errors"]),
    ...mapState("packet", [
      "packet",
      "sender",
      "searchCode",
      "scannedBarcode",
      "readyToPick"
    ])
  },

  watch: {
    scannedBarcode() {
      if (this.scannedBarcode && this.scannedBarcode === this.packet.barcode) {
        if (this.packet.adultContent) {
          this.$router.push({
            name: "packet-age-check",
            params: { code: this.searchCode },
            query: {
              platform: this.platform,
              device: this.device
            }
          });
        } else {
          this.deliverPacket({
            password: this.searchCode,
            barcode: this.packet.barcode,
            deliveryType: "password"
          }).then(resp => {
            if (resp) {
              this.sendBarcodeResultToMobile(true);
              this.sendHeadersToMobile(this.packet.barcode, this.readyToPick);
              this.showAcceptOverlayScreenAndRoute();
            }
          });
        }
      } else {
        this.sendBarcodeResultToMobile(false);
      }
    },

    errors(error) {
      if (error) {
        this.sendBarcodeResultToMobile(false);
      }
    }
  },

  methods: {
    ...mapActions(["resetPacketAndPaymentState"]),
    ...mapActions("packet", ["getPacketDetail", "deliverPacket"]),

    ...mapMutations(["clearErrors"]),

    showAcceptOverlayScreenAndRoute() {
      this.showAcceptOverlay = true;
      _.delay(() => {
        this.showAcceptOverlay = false;
        this.$router.replace({
          name: "packet-detail",
          params: { code: this.searchCode },
          query: {
            platform: this.platform,
            device: this.device
          }
        });
      }, 1000);
    },

    openMobileScan() {
      if (this.isMobile) {
        if (this.platform == MOBILE_TYPES.ANDROID) {
          window.PacketaPPA.openScan();
        } else if (this.platform == MOBILE_TYPES.IOS) {
          window.webkit.messageHandlers.openScan.postMessage({});
        }
      }
    },

    closePacketDetail() {
      if (window.confirm(this.$t("packetDetail.cancel-packet-confirm"))) {
        this.finish();
      }
    },

    finish() {
      this.$router.replace({
        name: "packet-search",
        query: {
          platform: this.platform,
          device: this.device
        }
      });
    }
  }
};
</script>
